<template>
  <div class="app defaultContainer-component">
    <Sidebar
      class="dontPrint"
      style="z-index: 100"
      @navToggled="navToggled($event)"
      :shiftDoctor="shiftDoctor"
    >
    </Sidebar>
    <div
      class="app-body mb-10"
      :class="sidebarOpen ? (deviceType != 'mobile' ? 'main-mr' : '') : ''"
    >
      <main class="main">
        <div class="container-fluid">
          <div class="element">&nbsp;</div>
          <router-view @doctorDashboard="getShiftDoctor"></router-view>
        </div>
      </main>
    </div>
    <hr style="" />
    <Footer class="dontPrint" :sidebarOpen="sidebarOpen"></Footer>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import Footer from "@/components/Footer";

export default {
  components: {
    Sidebar,
    Footer,
  },
  data() {
    return {
      sidebarOpen: false,
      shiftDoctor: "",
    };
  },
  methods: {
    navToggled(e) {
      this.sidebarOpen = e;
    },
    getShiftDoctor(value) {
      this.shiftDoctor = value;
    },
    //getDoctor() {
    //  this.$http
    //    .post(
    //      this.baseUrl + "/clinic/medicalTeam/dashbaord/getInfo",
    //      {},
    //      {
    //        headers: {
    //          Authorization: "Bearer: " + localStorage.getItem("token"),
    //        },
    //      }
    //    )
    //    .then((res) => {
    //      if (res.status == 200) {
    //        localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
    //        this.shiftDoctor = res.data.shiftDoctor;
    //        //this.$emit("doctorDashboard", res.data.shiftDoctor);
    //      } else {
    //        this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
    //      }
    //    })
    //    .catch((err) => {
    //      this.toast("خطا: " + err.data, "error");
    //    });
    //},
  },
  mounted() {
    let role = localStorage.getItem("role");
    //if (role == 'doctor'){
    //  this.getDoctor();

    //}
  },
};
</script>
