<template>
  <div class="dropdown-component">
    <transition name="fade">
      <v-list v-show="list.open" class="dropdown-list">
        <v-list-item
          v-for="(item, index) in list.children"
          :key="index"
          class="sub-items item-content dropdown-items"
          route
          :to="item.route"
        >
          <v-list-item-content class="item-content">
            <v-list-item-title
              @click="item.open = !item.open"
              class="item-content item-title me-4s ms-4"
            >
              <v-icon right small class="sidebar-icon">{{ item.icon }}</v-icon>
              <span class="item-name">{{ item.name }}</span>
              <v-spacer></v-spacer>
              <div class="menu-num" v-if="item.num && feedbackCount != '0'">
                <span>{{ feedbackCount }}</span>
              </div>
            </v-list-item-title>
            <dropdown v-if="item.children" :list="item"></dropdown>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-if="item.children">keyboard_arrow_down</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </transition>
  </div>
</template>
<script>
export default {
  name: "dropdown",
  props: ["list"],
  data() {
    return {
      feedbackCount: window.localStorage.unSeenfeedbacks,
    };
  },
  mounted() {
    //where we need to watch unseenFeedbacks
    window.addEventListener("feedbacks-changed", (event) => {
      this.feedbackCount = event.detail.storage;
    });
  },
};
</script>

<style lang="scss"></style>
