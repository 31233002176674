<template>
  <div class="sidebar-component">
    <loading v-if="vLoading" />
    <v-dialog
      v-if="role == 'reception' || role == 'dentistryNurse'"
      v-model="startShiftDialog"
      persistent
      width="700px"
    >
      <!-- NOTE INJA -->
      <v-card class="pa-3" style="border-radius: 20px">
        <v-card-title>
          <h3>آغاز شیفت</h3>
        </v-card-title>
        <v-card-text>
          <v-container style="">
            <h5 class="mb-5">
              لطفا نام کادر درمان و پرسنل شیفت را انتخاب کنید
            </h5>
            <div v-if="role == 'reception'">
              <v-row>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="shiftDoc"
                    :items="drNames.filter((x) => x.expertise != 'دندانپزشک')"
                    item-text="text"
                    item-value="value"
                    label="نام پزشک*"
                    class="pa-0"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    outlined
                    dense
                    v-model="shiftNurse"
                    :items="nurseNames"
                    item-text="text"
                    item-value="value"
                    label="نام پرستار*"
                    class="pa-0"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <!--<v-row>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    outlined
                    dense
                    v-else
                    v-model="dentistId"
                    :items="drNames"
                    item-text="text"
                    item-value="value"
                    label="دندانپزشک"
                    class="pa-0"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    outlined
                    dense
                    v-else
                    v-model="nurseDentistId"
                    :items="nurseNames"
                    item-text="text"
                    item-value="value"
                    label="دستیار دندانپزشک"
                    class="pa-0"
                  ></v-autocomplete>
                </v-col>
              </v-row>-->
              <v-row>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    outlined
                    dense
                    v-model="shiftDocOnCall"
                    :items="drNames.filter((x) => x.value != shiftDoc)"
                    item-text="text"
                    item-value="value"
                    label="نام پزشک آنکال"
                    class="pa-0"
                    disabled
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    outlined
                    dense
                    v-model="shiftNurseOnCall"
                    :items="nurseNames.filter((x) => x.value != shiftNurse)"
                    item-text="text"
                    item-value="value"
                    label="نام پرستارآنکال"
                    class="pa-0"
                    disabled
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="role == 'dentistryNurse'">
              <v-row>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    outlined
                    dense
                    v-else
                    v-model="shiftDoc"
                    :items="drNames.filter((x) => x.expertise == 'دندانپزشک')"
                    item-text="text"
                    item-value="value"
                    label="دندانپزشک"
                    class="pa-0"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    outlined
                    dense
                    v-else
                    v-model="shiftNurse"
                    :items="dentistNurseNames"
                    item-text="text"
                    item-value="value"
                    label="دستیار دندانپزشک"
                    class="pa-0"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <v-row
              ><v-col cols="6">
                <v-select
                  v-model="shift"
                  :items="shifts"
                  item-text="text"
                  item-value="value"
                  label="شیفت*"
                  outlined
                  dense
                ></v-select></v-col
              ><v-col cols="6" v-if="role == 'reception'">
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="servant"
                  :items="servantNames"
                  item-text="text"
                  item-value="value"
                  label="پرسنل خدمات"
                  outlined
                  dense
                ></v-autocomplete> </v-col
            ></v-row>
            <v-text-field
              label="نام پذیرش: "
              outlined
              dense
              v-model="userName"
              disabled
            ></v-text-field>
            <v-row class="mt-5">
              <i
                class="fa fa-exclamation-triangle fa-2x text-warning mt-1 ms-3 me-3"
                aria-hidden="true"
              ></i>
              <p class="mt-1 text-danger">
                تذکر: تمام مسئولیت قانونی موارد ثبت شده با مسئول پذیرش این شیفت
                خواهد بود
              </p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red-btn" outlined @click="startShiftDialog = false">
            لغو
          </v-btn>
          <v-btn
            class="primary-btn pa-5"
            :disabled="!shift || !shiftDoc || !shiftNurse"
            outlined
            @click="startShift()"
          >
            آغاز شیفت
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- NOTE TA INJA -->
    </v-dialog>
    <!-- NOTE dialog for ending shift which contains report form of shift  -->
    <v-dialog
      v-if="role == 'reception' || role == 'dentistryNurse'"
      v-model="endShiftDialog"
      persistent
      width="800px"
    >
      <v-card style="border-radius: 20px" class="pa-3">
        <v-card-title>
          <h3>فرم پایان شیفت</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <v-col md="6" cols="12">
                <v-select
                  v-model="report.shift"
                  :items="shifts"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  label="شیفت *"
                ></v-select>
              </v-col>
              <v-col md="6" cols="12">
                <span id="dateFrom3">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="report.date"
                    label=" تاریخ  "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="report.date"
                  element="dateFrom3"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <span id="startshift">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="schedule"
                    v-model="report.shiftStart"
                    label=" از ساعت  "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="report.shiftStart"
                  element="startshift"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
              <v-col md="6" cols="12">
                <span id="endshift">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="report.shiftEnd"
                    label=" لغایت  "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="report.shiftEnd"
                  element="endshift"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
            </v-row>
            <div v-if="role == 'reception'">
              <v-row>
                <v-col md="6" cols="12">
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="report.lastShiftDoc"
                    :items="drNames.filter((x) => x.expertise != 'دندانپزشک')"
                    multiple
                    item-text="text"
                    item-value="text"
                    label="نام پزشک *"
                    class="pa-0"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col md="6" cols="12">
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="report.lastShiftNurse"
                    :items="nurseNames"
                    multiple
                    item-text="text"
                    item-value="text"
                    label="نام پرستار *"
                    outlined
                    dense
                    class="pa-0"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <!--<v-row>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="report.lastShiftDentist"
                    :items="drNames.filter((x) => x.expertise == 'دندانپزشک')"
                    multiple
                    item-text="text"
                    item-value="text"
                    outlined
                    dense
                    label="نام دندانپزشک"
                    class="pa-0"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="report.lastShiftNurseDentist"
                    :items="nurseNames"
                    multiple
                    item-text="text"
                    item-value="text"
                    label="نام دستیار دندانپزشک"
                    class="pa-0"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>-->
            </div>
            <div v-else-if="role == 'dentistryNurse'">
              <v-row>
                <v-col md="6" cols="12">
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="report.lastShiftDoc"
                    :items="drNames.filter((x) => x.expertise == 'دندانپزشک')"
                    multiple
                    item-text="text"
                    item-value="text"
                    outlined
                    dense
                    label="نام دندانپزشک"
                    class="pa-0"
                  ></v-autocomplete>
                </v-col>
                <v-col md="6" cols="12">
                  <v-progress-circular
                    v-if="busy"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-autocomplete
                    v-else
                    v-model="report.lastShiftNurse"
                    :items="dentistNurseNames"
                    multiple
                    item-text="text"
                    item-value="text"
                    label="نام دستیار دندانپزشک"
                    class="pa-0"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <v-row align="center">
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="userName"
                  disabled
                  label="پذیرش"
                  outlined
                  dense
                  class="pa-0"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12" v-if="role == 'reception'">
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-autocomplete
                  v-else
                  v-model="report.lastShiftServant"
                  :items="servantNames"
                  multiple
                  outlined
                  dense
                  item-text="text"
                  item-value="text"
                  label="پرسنل خدمات"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <hr />
            <h3 class="text-center primary--text">خلاصه کارکرد مالی</h3>
            <br />
            <v-row>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.pos"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label=" کارتخوان(﷼) *"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.cash"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="نقدی(﷼) *"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.lack"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="مبلغ کسری صندوق(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.extra"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="مبلغ اضافه صندوق(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.talab"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="طلب از بیمار(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col md="6" cols="12">
                <vuetify-money
                  v-model="report.debt"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  label="بدهی به بیمار(﷼)"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-text-field
              outlined
              label="توضیحات صندوق"
              v-model="report.cashDescription"
              type="text"
            ></v-text-field>
            <hr />
            <div v-if="role == 'reception'">
              <h4 class="text-center primary--text">نسخ الکترونیک ثبت شده</h4>
              <br />
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    label="بیمه تامین اجتماعی"
                    outlined
                    dense
                    v-model="report.ePrepSocial"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    label="بیمه خدمات سلامت"
                    outlined
                    dense
                    v-model="report.ePrepHealth"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    label="بیمه نیروهای مسلح"
                    outlined
                    dense
                    v-model="report.ePrepMilitary"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <hr />
            </div>
            <div v-if="role == 'reception'">
              <h4 class="text-center primary--text">ارجاعات تخصصی</h4>
              <br />
              <h6 class="text-right">
                آیا در فرآیند اجرای شیفت، ارجاعی به متخصصین انجام گرفته است؟
              </h6>
              <v-radio-group v-model="report.isReferral" row>
                <v-radio label="بله" value="yes"></v-radio>
                <v-radio label="خیر" value="no"></v-radio>
              </v-radio-group>
              <div v-if="report.isReferral == 'yes'">
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد آزمایشگاه"
                      outlined
                      dense
                      v-model="report.referral.lab.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران آزمایشگاه"
                      outlined
                      dense
                      v-model="report.referral.lab.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد فیزیوتراپی"
                      outlined
                      dense
                      v-model="report.referral.physio.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران فیزیوتراپی"
                      outlined
                      dense
                      v-model="report.referral.physio.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد رادیولوژی"
                      outlined
                      dense
                      v-model="report.referral.radio.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران رادیولوژی"
                      outlined
                      dense
                      v-model="report.referral.radio.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد دندانپزشکی"
                      outlined
                      dense
                      v-model="report.referral.dentistery.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران دندانپزشکی"
                      outlined
                      dense
                      v-model="report.referral.dentistery.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد متخصص زنان"
                      outlined
                      dense
                      v-model="report.referral.women.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران متخصص زنان"
                      outlined
                      dense
                      v-model="report.referral.women.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد متخصص اطفال"
                      outlined
                      dense
                      v-model="report.referral.babies.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران متخصص اطفال"
                      outlined
                      dense
                      v-model="report.referral.babies.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد داخلی"
                      outlined
                      dense
                      v-model="report.referral.internal.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران داخلی"
                      outlined
                      dense
                      v-model="report.referral.internal.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد قلب و عروق"
                      outlined
                      dense
                      v-model="report.referral.heart.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران قلب و عروق"
                      outlined
                      dense
                      v-model="report.referral.heart.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد ارتوپدی"
                      outlined
                      dense
                      v-model="report.referral.ortho.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران ارتوپدی"
                      outlined
                      dense
                      v-model="report.referral.ortho.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد ENT"
                      outlined
                      dense
                      v-model="report.referral.ent.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران ENT"
                      outlined
                      dense
                      v-model="report.referral.ent.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد جراحی"
                      outlined
                      dense
                      v-model="report.referral.surgery.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران جراحی"
                      outlined
                      dense
                      v-model="report.referral.surgery.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد اورولوژی"
                      outlined
                      dense
                      v-model="report.referral.orology.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران اورولوژی"
                      outlined
                      dense
                      v-model="report.referral.orology.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد عفونی"
                      outlined
                      dense
                      v-model="report.referral.infectious.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران عفونی"
                      outlined
                      dense
                      v-model="report.referral.infectious.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="تعداد پوست و مو"
                      outlined
                      dense
                      v-model="report.referral.skin.no"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="اسامی بیماران پوست و مو"
                      outlined
                      dense
                      v-model="report.referral.skin.names"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
            <hr />
            <h4 class="text-center primary--text">خلاصه اتفاقات شیفت</h4>
            <br />
            <h6 class="text-right">
              1. آیا کمبودی در وسایل و امکانات و تجهیزات در شیفت وجود داشت؟
            </h6>
            <v-radio-group v-model="report.isFacilitiesShortage" row>
              <v-radio label="بله" value="yes"></v-radio>
              <v-radio label="خیر" value="no"></v-radio>
            </v-radio-group>
            <v-text-field
              outlined
              v-if="report.isFacilitiesShortage == 'yes'"
              label="توضیحات و شرح کمبود"
              v-model="report.facilitiesShortage"
              type="text"
            ></v-text-field>
            <h6 class="text-right" style="line-height: 30px">
              {{
                role == "dentistryNurse"
                  ? "آیا در طول مدت شیفت بیمار یا مراجعه کننده ای اعتراض /ناراحتی/ شکایت یا درگیری در خصوص نحوه ارایه خدمات درمانگاه دندانپزشکی و یا پزشک درمانگاه داشت؟"
                  : "آیا در طول مدت شیفت بیمار یا مراجعه کننده ای اعتراض /ناراحتی/ شکایت یا درگیری در خصوص نحوه ارایه خدمات درمانگاه اعم از خدمات/پذیرش/ پرستاری/ ازمایشگاه/ دندانپزشکی/ داروخانه و یا پزشک درمانگاه داشت؟"
              }}
            </h6>
            <v-radio-group v-model="report.isDissatisfaction" row>
              <v-radio label="بله" value="yes"></v-radio>
              <v-radio label="خیر" value="no"></v-radio>
            </v-radio-group>
            <v-text-field
              outlined
              v-if="report.isDissatisfaction == 'yes'"
              label="اگر بلی توضیح دهید"
              v-model="report.dissatisfaction"
              type="text"
            ></v-text-field>
            <div class="text-right mb-6">
              <h6 class="mb-2">
                سایر موارد (بیماران بدون نوبت/ توضیحات شکایات):
              </h6>
              <vue-ckeditor v-model="report.others" :config="config" />
            </div>
            <div class="text-right mb-6">
              <h6 class="mb-2">پیشنهاد جهت ارتقاء فرآیند کاری درمانگاه:</h6>
              <vue-ckeditor v-model="report.suggestions" :config="config" />
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :disabled="
              !(report.pos || report.cash) ||
              !(report.lastShiftDoc && report.lastShiftNurse && report.shift) ||
              !report.ePrepHealth ||
              !report.ePrepSocial ||
              !report.ePrepMilitary
            "
            class="primary-btn pa-5"
            outlined
            @click="endShift()"
          >
            ارسال فرم و پایان شیفت
          </v-btn>
          <v-btn class="red-btn pa-5" outlined @click="endShiftDialog = false">
            لغو
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="role == 'reception' || role == 'dentistryNurse'"
      v-model="errorDialog"
      max-width="600px"
    >
      <v-card class="dialog-card">
        <v-card-title>
          <h3>عدم دسترسی</h3>
        </v-card-title>
        <v-card-text>
          <v-container class="common-text">
            <v-row>
              <v-col style="line-height: 22px">
                <p class="text-justify">
                  تنها در صورتی قادر به ارائه گزارش و اتمام شیفت هستید که شیفت
                  را آغاز کرده باشید. لطفا با اکانت کارمند پذیرش اصلی این شیفت
                  وارد سیستم شوید.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>پذیرش اصلی: </span
                ><span>{{ shiftDetails.receptionName }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-btn pa-5" outlined @click="errorDialog = false">
            بستن
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- NOTE dialog for showing current shift to admin -->
    <v-dialog
      v-if="role != 'reception' || role == 'dentistryNurse'"
      v-model="currentShiftDialog"
      max-width="600px"
    >
      <!-- NOTE INJA -->
      <v-card>
        <v-card-title class="mb-2">
          <span style="font-size: 18px; font-weight: bold" class="primary--text"
            >جزییات شیفت</span
          >
        </v-card-title>
        <v-card-text style="font-size: 16px; line-height: 22px">
          <v-container>
            <v-row>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-else>
                  <span class="">شیفت: </span
                  ><span>{{ shiftDetails.subject }}</span>
                </div>
              </v-col>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-else>
                  <span>شروع شیفت: </span
                  ><span>{{ shiftDetails.startTime }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-if="!busy && shiftDetails.docId && drNames != []">
                  <span>پزشک: </span
                  ><span>{{
                    shiftDetails.docId &&
                    drNames &&
                    drNames.filter((x) => x.value == shiftDetails.docId)[0]
                      ? drNames.filter((x) => x.value == shiftDetails.docId)[0]
                          .text
                      : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-if="!busy && shiftDetails.nurseId && nurseNames != []">
                  <span>پرستار: </span
                  ><span>{{
                    shiftDetails.nurseId &&
                    nurseNames &&
                    nurseNames.filter((x) => x.value == shiftDetails.nurseId)[0]
                      ? nurseNames.filter(
                          (x) => x.value == shiftDetails.nurseId
                        )[0].text
                      : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="shiftDetails.dentistId">
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-if="!busy && drNames != []">
                  <span>دندانپزشک: </span
                  ><span>{{
                    shiftDetails.dentistId &&
                    drNames &&
                    drNames.filter((x) => x.value == shiftDetails.dentistId)[0]
                      ? drNames.filter(
                          (x) => x.value == shiftDetails.dentistId
                        )[0].text
                      : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="shiftDetails.nurseDentistId">
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-if="!busy && dentistNurseNames != []">
                  <span>دستیار دندانپزشک: </span
                  ><span>{{
                    shiftDetails.nurseDentistId &&
                    nurseNames &&
                    dentistNurseNames.filter(
                      (x) => x.value == shiftDetails.nurseDentistId
                    )[0]
                      ? dentistNurseNames.filter(
                          (x) => x.value == shiftDetails.nurseDentistId
                        )[0].text
                      : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="shiftDetails.oncallDocId">
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-if="!busy && drNames != []">
                  <span>نام پزشک آنکال: </span
                  ><span>{{
                    shiftDetails.oncallDocId &&
                    drNames &&
                    drNames.filter(
                      (x) => x.value == shiftDetails.oncallDocId
                    )[0]
                      ? drNames.filter(
                          (x) => x.value == shiftDetails.oncallDocId
                        )[0].text
                      : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="shiftDetails.oncallNurseId">
              <v-col>
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-if="!busy && nurseNames != []">
                  <span>نام پرستارآنکال: </span
                  ><span>{{
                    shiftDetails.oncallNurseId &&
                    nurseNames &&
                    nurseNames.filter(
                      (x) => x.value == shiftDetails.oncallNurseId
                    )[0]
                      ? nurseNames.filter(
                          (x) => x.value == shiftDetails.oncallNurseId
                        )[0].text
                      : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="shiftDetails.servantId"
              ><v-col cols="6">
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-if="!busy && servantNames != []">
                  <span>پرسنل خدمات: </span
                  ><span>{{
                    shiftDetails.servantId &&
                    servantNames &&
                    servantNames.filter(
                      (x) => x.value == shiftDetails.servantId
                    )[0]
                      ? servantNames.filter(
                          (x) => x.value == shiftDetails.servantId
                        )[0].text
                      : ""
                  }}</span>
                </div>
              </v-col></v-row
            >
            <v-row>
              <v-col cols="6">
                <v-progress-circular
                  v-if="busy"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-else>
                  <span>پذیرش: </span
                  ><span>{{ shiftDetails.receptionName }}</span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="currentShiftDialog = false">
            بستن
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- NOTE TA INJA -->
    </v-dialog>
    <v-app-bar app fixed :class="drawer ? 'header-right' : ''">
      <v-app-bar-nav-icon class="menu-button" @click="toggle">
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn
        :loading="!shiftDetails"
        @click="openShiftModal"
        class="shift-btn"
        v-if="role == 'reception' || role == 'dentistryNurse'"
      >
        <v-icon x-small class="ms-1 me-1" :color="statusColor"
          >mdi-moon-full</v-icon
        >
        {{
          shiftDetails.status
            ? "شیفت " + shiftDetails.subject
            : "شیفتی در جریان نیست"
        }}
      </v-btn>
      <v-menu offset-y bottom class="user-menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="me-2 ms-2 user-btn" text v-bind="attrs" v-on="on">
            <v-icon large class="user-icon">person_outline</v-icon>
          </v-btn>
        </template>
        <v-list class="user-list">
          <v-list-item
            class="user-list-item"
            v-if="
              (role == 'reception' || role == 'dentistryNurse') &&
              !shiftDetails.status
            "
            @click="showStartShift()"
            >آغاز شیفت</v-list-item
          >
          <v-list-item
            class="user-list-item"
            v-if="
              (role == 'reception' || role == 'dentistryNurse') &&
              shiftDetails.status
            "
            @click="showEendShift()"
            >پایان شیفت</v-list-item
          >

          <v-list-item
            v-if="role != 'reception'"
            disabled
            class="user-list-item"
            >کاربر:
            {{
              role == "admin"
                ? "مدیر سامانه"
                : role == "reception"
                ? "پذیرش"
                : role == "labReception"
                ? "مسئول آزمایشگاه"
                : role == "doctor"
                ? "پزشک"
                : role == "nurse"
                ? "پرستار"
                : role == "dentistryNurse"
                ? "پذیرش دندانپزشکی"
                : role == "dentistryAdmin"
                ? "مدیر درمانگاه دندانپزشکی"
                : "مدیر درمانگاه"
            }}
          </v-list-item>
          <v-list-item class="text-center user-list-item" @click="logout()">
            خروج</v-list-item
          >
        </v-list>
      </v-menu>
    </v-app-bar>

    <transition name="sidebar">
      <v-navigation-drawer
        app
        v-model="drawer"
        class="drawer"
        right
        :hide-overlay="true"
      >
        <!--logo-->
        <v-list-item class="user-profile">
          <v-list-item-content>
            <v-toolbar-title class="text-uppercase pa-8 mt-5">
              <router-link
                :to="
                  role == 'reception'
                    ? '/reception/dashboard'
                    : role == 'admin' || role == 'manager'
                    ? '/admin/dashboard'
                    : role == 'labReception'
                    ? '/labReception/laboratoryRequests'
                    : role == 'dentistryNurse'
                    ? '/dentistryNurse/dashboard'
                    : ''
                "
              >
                <v-img
                  src="@/assets/images/big.png"
                  alt=""
                  class="logo"
                  max-height="auto"
                  max-width="150"
                ></v-img>
              </router-link>
            </v-toolbar-title>
          </v-list-item-content>
        </v-list-item>
        <hr class="mt-5 mb-0 m-auto" style="width: 80%" />
        <v-list class="menu-list">
          <!-- menu items -->
          <v-list-item
            class="menu-items ps-0 pe-0 my-1"
            v-for="(item, index) in navigation"
            :key="index"
            route
            :to="item.route"
          >
            <v-list-item-content>
              <v-list-item-title
                class="item-content item-title black--text text--lighten-5 pe-3 ps-3"
                @click="item.open = !item.open"
              >
                <v-icon right small class="sidebar-icon">{{
                  item.icon
                }}</v-icon>
                {{ item.name }}
                <div
                  class="menu-num ms-3"
                  v-if="item.num && feedbackCount != '0'"
                >
                  <span>{{ feedbackCount }}</span>
                </div>
                <v-spacer></v-spacer>
                <div
                  class="menu-num ms-3"
                  v-if="item.name == 'دبیرخانه' && notificationCount != '0'"
                >
                  <span class="fs-xsmall pt-1">{{ notificationCount }}</span>
                </div>
                <v-icon
                  v-if="item.children && !item.open"
                  left
                  class="down-icon sidebar-icon"
                  >keyboard_arrow_right</v-icon
                >
                <v-icon
                  v-if="item.children && item.open"
                  left
                  class="down-icon sidebar-icon"
                  >keyboard_arrow_down</v-icon
                >
              </v-list-item-title>
              <Dropdown v-if="item.children" :list="item"></Dropdown>
            </v-list-item-content>
          </v-list-item>
          <!-- logout button -->
          <v-list-item
            class="menu-items ps-0 pe-0 my-1 logout-item mb-15"
            @click="logout()"
          >
            <v-list-item-content>
              <v-list-item-title
                class="item-content item-title black--text text--lighten-5 px-3"
              >
                <v-icon right small class="sidebar-icon"> logout </v-icon>
                خروج
                <v-spacer></v-spacer>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </transition>
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown";
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters, mapActions, mapMutations } from "vuex";
import VueCkeditor from "vue-ckeditor2";

export default {
  name: "Navbar",
  props: ["shiftDoctor"],
  components: {
    Dropdown,
    datePicker: VuePersianDatetimePicker,
    VueCkeditor,
  },
  data() {
    return {
      role: window.localStorage.role,
      isDentistNurse: window.localStorage.isDentistNurse,
      drawer: false,
      deviceType: "",
      report: {
        shift: "",
        date: moment(new Date()).format("jYYYY/jMM/jDD"),
        shiftStart: "00:00",
        shiftEnd: "23:59",
        lastShiftNurse: [],
        lastShiftDoc: [],
        lastShiftServant: [],
        reception: "",
        pos: "",
        cash: "",
        lack: "",
        extra: "",
        talab: "",
        debt: "",
        cashDescription: "",
        isFacilitiesShortage: "",
        facilitiesShortage: "",
        isDissatisfaction: "",
        dissatisfaction: "",
        others: "",
        suggestions: "",
        ePrepHealth: "",
        ePrepMilitary: "",
        ePrepSocial: "",
        isReferral: "no",
        referral: {
          lab: { no: 0, names: "" },
          physio: { no: 0, names: "" },
          radio: { no: 0, names: "" },
          dentistery: { no: 0, names: "" },
          women: { no: 0, names: "" },
          babies: { no: 0, names: "" },
          internal: { no: 0, names: "" },
          heart: { no: 0, names: "" },
          ortho: { no: 0, names: "" },
          ent: { no: 0, names: "" },
          surgery: { no: 0, names: "" },
          orology: { no: 0, names: "" },
          infectious: { no: 0, names: "" },
          skin: { no: 0, names: "" },
        },
      },
      busy: false,
      shiftDoc: "",
      shiftNurse: "",
      changing: false,
      shiftDocOnCall: "",
      shiftNurseOnCall: "",
      dentistId: "",
      nurseDentistId: "",
      servant: "",
      shift: "",
      sidebarOpen: false,
      shifts: [
        { value: "صبح", text: "صبح" },
        { value: "عصر", text: "عصر" },
        { value: "شب", text: "شب" },
      ],
      useName: "",
      startShiftDialog: false,
      endShiftDialog: false,
      doResetDialog: false,
      errorDialog: false,
      currentShiftDialog: false,
      reactivity: false,
      config: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "Font",
          ],
        ],
      },
      unSeenfeedbacks: "",
      feedbackCount: window.localStorage.unSeenfeedbacks,
      notificationCount: 0,
    };
  },

  methods: {
    ...mapActions(["getDocs", "getCurrent"]),
    ...mapMutations(["setUserName"]),
    toggle() {
      this.drawer = !this.drawer;
    },
    checkToken() {
      this.$http
        .post(
          this.baseUrl + "/clinic/requirements/checkToken",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (res.data.status == "ok") {
              this.notificationCount = res.data.notificationCount;
              this.setUserName(res.data.name);
              localStorage.setItem("role", res.data.role);
              localStorage.setItem("unSeenfeedbacks", res.data.unSeenfeedbacks);
              //dispatch a CustomEvent when add feedbacks to storage:
              window.dispatchEvent(
                new CustomEvent("feedbacks-changed", {
                  detail: {
                    storage: localStorage.getItem("unSeenfeedbacks"),
                  },
                })
              );
            } else {
              this.toast("نشست شما در سامانه منقضی شده است", "error");
              this.$router.push("/login");
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch(() => {
          this.toast("نشست شما در سامانه منقضی شده است", "error");
          this.$router.push("/login");
        });
    },
    logout() {
      window.localStorage.clear();
      window.location.replace("/login");
    },
    openShiftModal() {
      if (this.role == "reception" || this.role == "dentistryNurse") {
        this.shiftDetails.status ? this.showEendShift() : this.showStartShift();
      } else {
        if (this.shiftDetails.status) {
          this.getCurrent();
          this.currentShiftDialog = true;
        }
      }
    },
    showStartShift() {
      this.startShiftDialog = true;
    },
    startShift() {
      this.changing = true;
      this.busy = true;
      if (this.role == "reception") {
        this.$http
          .post(
            this.baseUrl + "/clinic/shift/start",
            {
              docId: this.shiftDoc,
              nurseId: this.shiftNurse,
              subject: this.shift,
              oncallDocId: this.shiftDocOnCall,
              oncallNurseId: this.shiftNurseOnCall,
              servantId: this.servant,
              dentistId: this.dentistId,
              nurseDentistId: this.nurseDentistId,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.busy = false;
            this.changing = false;
            this.getCurrent();
            if (res.status == 201) {
              this.toast("شیفت با موفقیت آغاز گردید.", "success");
              this.startShiftDialog = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.busy = false;
            this.changing = false;
          });
      } else if (this.role == "dentistryNurse") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/shift/start",
            {
              docId: this.shiftDoc,
              nurseId: this.shiftNurse,
              subject: this.shift,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.busy = false;
            this.changing = false;
            this.getCurrent();
            if (res.status == 201) {
              this.toast("شیفت با موفقیت آغاز گردید.", "success");
              this.startShiftDialog = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.busy = false;
            this.changing = false;
          });
      }
    },
    showEendShift() {
      if (this.shiftDetails.receptionName == this.userName) {
        this.report = {
          shift: "",
          date: moment(new Date()).format("jYYYY/jMM/jDD"),
          shiftStart: "00:00",
          shiftEnd: "23:59",
          lastShiftNurse: [],
          lastShiftDoc: [],
          lastShiftServant: [],
          reception: "",
          pos: "",
          cash: "",
          lack: "",
          extra: "",
          talab: "",
          debt: "",
          cashDescription: "",
          isFacilitiesShortage: "",
          facilitiesShortage: "",
          isDissatisfaction: "",
          dissatisfaction: "",
          others: "",
          suggestions: "",
          ePrepHealth: "",
          ePrepMilitary: "",
          ePrepSocial: "",
          isReferral: "no",
          referral: {
            lab: { no: 0, names: "" },
            physio: { no: 0, names: "" },
            radio: { no: 0, names: "" },
            dentistery: { no: 0, names: "" },
            women: { no: 0, names: "" },
            babies: { no: 0, names: "" },
            internal: { no: 0, names: "" },
            heart: { no: 0, names: "" },
            ortho: { no: 0, names: "" },
            ent: { no: 0, names: "" },
            surgery: { no: 0, names: "" },
            orology: { no: 0, names: "" },
            infectious: { no: 0, names: "" },
            skin: { no: 0, names: "" },
          },
        };
        this.endShiftDialog = true;
      } else {
        this.errorDialog = true;
      }
    },
    endShift() {
      this.changing = true;
      this.busy = true;
      this.report.reception = this.userName;
      this.report.cash = this.report.cash ? this.report.cash : "0";
      this.report.pos = this.report.pos ? this.report.pos : "0";
      if (this.role == "reception") {
        this.$http
          .post(
            this.baseUrl + "/clinic/shift/end",
            {
              shiftId: this.shiftDetails.id,
              report: JSON.stringify(this.report),
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.busy = false;
            this.changing = false;
            if (res.status == 200) {
              this.toast("شیفت با موفقیت پایان یافت.", "success");
              this.getCurrent();
              this.endShiftDialog = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            console.log(err);
            this.toast("خطا: " + err.response.data, "error");
            this.busy = false;
            this.changing = false;
          });
      } else if (this.role == "dentistryNurse") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/shift/end",
            {
              shiftId: this.shiftDetails.id,
              report: JSON.stringify(this.report),
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.busy = false;
            this.changing = false;
            if (res.status == 200) {
              this.toast("شیفت با موفقیت پایان یافت.", "success");
              this.getCurrent();
              this.endShiftDialog = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            console.log(err);
            this.toast("خطا: " + err.response.data, "error");
            this.busy = false;
            this.changing = false;
          });
      }
    },
    onMounted() {
      if (!window.localStorage.getItem("token")) {
        this.$router.push("/login");
      }
      if (this.role == "reception") {
        this.reception = this.userName;
      }
    },
  },
  mounted() {
    this.onMounted();
    if (window.screen.width < 768) {
      this.deviceType = "mobile";
    } else if (window.screen.width > 768 && window.screen.width < 1024) {
      this.deviceType = "tablet";
    }
    //where we need to watch unseenFeedbacks
    window.addEventListener("feedbacks-changed", (event) => {
      this.feedbackCount = event.detail.storage;
    });
  },
  watch: {
    drawer(val) {
      this.$emit("navToggled", val);
    },
  },
  created() {
    this.checkToken();
    if (this.role != "admin" || this.role != "manager") {
      this.getCurrent();
    }
    this.getDocs();
  },
  computed: {
    ...mapGetters([
      "drNames",
      "nurseNames",
      "servantNames",
      "shiftDetails",
      "userName",
      "dentistNurseNames",
    ]),
    navigation() {
      let all = this.$store.getters.nav;
      let nav;
      switch (this.role) {
        case "admin":
          nav = all.adminItems;
          break;
        case "manager":
          nav = all.managerItems;
          break;
        case "reception":
          nav = all.receptionItems;
          break;
        case "labReception":
          nav = all.labReceptionItems;
          break;
        case "doctor":
          nav = this.shiftDoctor ? all.shiftDoctorItems : all.doctorItems;
          break;
        case "nurse":
          nav = all.nurseItems;
          break;
        case "dentistryNurse":
          nav = this.isDentistNurse ? all.nurseItems : all.dentistryNurseItems;
          break;
        case "dentistryAdmin":
          nav = all.dentistryAdminItems;
          break;
      }
      return nav;
    },

    name() {
      return this.$route.name;
    },

    list() {
      return this.$route.matched.filter(
        (route) => route.name || route.meta.label
      );
    },
    statusColor() {
      if (this.role == "reception" || this.role == "dentistryNurse") {
        if (this.shiftDetails.status) {
          if (this.shiftDetails.receptionName == this.userName) {
            return "green";
          } else {
            return "#FFBA01";
          }
        } else {
          return "red";
        }
      } else {
        if (this.shiftDetails.status) {
          return "green";
        } else {
          return "red";
        }
      }
    },
  },
};
</script>
