<template>
  <v-footer
    padless
    :class="sidebarOpen ? 'footer-mr' : ''"
    class="footer footer-component"
  >
    <v-container class="footer-container">
      <v-row class="footer-row text-center">
        <v-col style="direction: rtl" class="f-text text-center">
          سامانه مدیریت درمانگاه
          <a href="https://tutiateb.com/" target="_blank" class="hover-link">
            سامانه مدیریت درمان توتیاطب
          </a>
          <span class="me-1"
            >&copy; {{ moment(new Date()).format("jYYYY") }}</span
          >
        </v-col>
        <v-col style="direction: rtl" class="f-text text-center">
          محصولی از
          <a href="https://fa.sarmadbs.com/" target="_blank" class="hover-link">
            خدمات تجارت سرمد
          </a>
        </v-col>
        <v-col style="direction: rtl" class="f-text text-center">
          نسخه :
          <a v-if="role == 'admin'" :href="'/admin/ChangeLog'" target="_blank">
            {{ versionNum }}
          </a>
          <span v-else class="primary--text">{{ versionNum }}</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { version } from "../../package.json";
import moment from "moment-jalaali";
export default {
  props: ["sidebarOpen"],
  data: function () {
    return {
      versionNum: "",
      moment: moment,
      role:''
    };
  },
  //watch: {
  //  sidebarOpen: function(newDrawer, oldDrawer) {
  //    console.log("drawer changed from " + newDrawer + " to " + newDrawer);
  //  },
  //},
  methods: {
    getVersion() {
      this.versionNum = version;
    },
  },
  mounted() {
    this.getVersion();
     this.role = localStorage.getItem("role");
  },
};
</script>

<style lang="scss">
//@import '@/assets/scss/scss_arina/footer.scss';
</style>
